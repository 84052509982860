import dayjs from 'dayjs';

/**
 * 将分钟转换为小时+分钟的数组
 * @param minutes 分钟数
 * @returns
 */
export const convertMinutesToHoursAndMinutes = (minutes: number): [number, number] => {
  // 校验输入是否为非负数
  if (minutes < 0) {
    throw new Error('Minutes cannot be negative');
  }

  // 计算小时数和剩余分钟数
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  // 返回一个包含小时和分钟的数组
  return [hours, remainingMinutes];
};

/**
 * 格式化：星期年月日时
 * @param date
 * @returns
 */
export const formatDateAndWeekAndTime = (date: string, lang: string) => {
  if (!date) {
    return '';
  }

  const timeFormat = dayjs(date).format('h:mm:ss A');

  if (lang === 'zh') {
    const dateFormat = dayjs(date, { locale: 'zh' }).format('YYYY-MM-DD');
    const weekFormat = dayjs(date, { locale: 'zh' }).format('dddd');
    return (
      <>
        <span>{dateFormat}</span>
        <span>&nbsp;&nbsp;{weekFormat}</span>
        <span>&nbsp;&nbsp;{timeFormat}</span>
      </>
    );
  }

  const dateAndWeekFormat = dayjs(date).format('ddd, MMM D, YYYY');
  return (
    <>
      <span>{dateAndWeekFormat}</span>
      <span>&nbsp;&nbsp;{timeFormat}</span>
    </>
  );
};

/**
 * 格式化：年月日时
 * @param date
 * @returns
 */
export const formatDateAndTime = (date: string, lang: string) => {
  if (!date) {
    return '';
  }

  const timeFormat = dayjs(date).format('h:mm A');

  if (lang === 'zh') {
    const dateFormat = dayjs(date, { locale: 'zh' }).format('YYYY-MM-DD');
    return (
      <>
        <span>{dateFormat}</span>
        <span>&nbsp;{timeFormat}</span>
      </>
    );
  }

  const dateFormat = dayjs(date).format('MMM D, YYYY');
  return (
    <>
      <span>{dateFormat}</span>
      <span>&nbsp;{timeFormat}</span>
    </>
  );
};

/**
 * 格式化：时
 * @param date
 * @returns
 */
export const formatTime = (date: string) => {
  if (!date) {
    return '';
  }
  const timeFormat = dayjs(date).format('h:mm A');

  return timeFormat;
};
